import React, { useState } from "react"
import images from "../../components/assets/images/logoo.png"
import { Link, useHistory } from "react-router-dom";
import AuthService from "./AuthService ";

const Search = ({ user }) => {
  const history = useHistory();
  const isLoggedIn = AuthService.isLoggedIn();
  const [showFilterBox, setShowFilterBox] = useState(false);
  const handleFilterClick = () => {
    setShowFilterBox(!showFilterBox);
  };
  const logout = () => {
    window.open("http://localhost:5000/auth/logout", "_self");
  };


  const handleClick = () => {
    if (isLoggedIn || user) {
      history.push("/sub");
    } else {
      history.push("/account");
    }
    
  };
  const handleLogout = () => {
    AuthService.logout();
    history.push("/");
  };
  
  return (
    <>
      <section className='search'>

      {/* {user ? (
        <ul className="list">
          <li className="listItem">
            <img
              src={user.photos[0].value}
              alt=""
              className="avatar"
            />
          </li>
          <li className="listItem">{user.displayName}</li>
          <li className="listItem" onClick={logout}>
            Logout
          </li>
        </ul>
      ) : (
        <Link className="link" to="/account">
          Login
        </Link>
      )} */}
        <div className='container c_flex'>
        <Link to="/">
            <div className='logo width '>
              <img src={images} alt='' />
            </div>
            
          </Link>

       


          <div className='icon f_flex width'>
          <div className='icon f_flex width'>
  {isLoggedIn || user ? (
     <Link to='/profile'>
     {user ? (
       <img
         src={user.photos[0].value}
         alt=""
         className="avatar"
         style={{borderRadius:"50%",width:43 , marginLeft:5 }}
       />
     ) : (
       <i className='fa fa-user icon-circle'></i>
     )}
   </Link>
  ) : (
    <Link to="/account">
      <i className='fa fa-user icon-circle'></i>
    </Link>
  )}
  <div className='cart' onClick={handleClick}>
    <i className='fa fa-plus icon-circle'></i>
    {/* <span>{CartItem.length === 0 ? "" : CartItem.length}</span> */}
  </div>
  {(isLoggedIn || user) && (
            // Conditionally render logout button if user is logged in or user exists
            <div className='logout' onClick={user ? logout : handleLogout}>
              <i className='fa fa-sign-out icon-circle'></i>
              <p>{user ? '' : ''}</p>
            </div>
          )}



</div>
          </div>
         
        </div>
  
      </section>
    </>
  )
}

export default Search








// {user ? (
//   <>
//     <Link to='/profile'>
//       <img
//         src={user.photos[0].value}
//         alt=""
//         className="user-avatar"
//       />
//     </Link>
//     <p>{user.displayName}</p>
//     <div className='cart' onClick={handleClick}>
//       <i className='fa fa-plus icon-circle'></i>
//       {/* <span>{CartItem.length === 0 ? "" : CartItem.length}</span> */}
//     </div>
//     <div className='logout' onClick={handleLogout}>
//       <i className='fa fa-sign-out icon-circle'></i>
//       <p> </p>
//     </div>
//   </>
// ) : (
//   <Link to="/account">
//     <i className='fa fa-user icon-circle'></i>
//   </Link>
// )}
