import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react"

const VillasForRent = () => {

  const [Advertisement, setAdvertisement] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const divStyle = {
      borderRadius: '10px', 
     // You can adjust the value to change the level of rounding
    };
    const [selectedCity, setSelectedCity] = useState("All"); 

    const handleCityChange = (selectedCity) => {
      setSelectedCity(selectedCity);
    };
  useEffect(() => {
    async function fetchAdvertisement() {
      try {
        const response = await fetch('https://aqarukum.com/api/users/allAdvertisement');
        const data = await response.json();

        if (response.ok) {
          setAdvertisement(data);
        } else {
          setError(error);
        }
      } catch (error) {
        console.log(  error);
      } finally {
        setLoading(false);
      }
    }

    fetchAdvertisement();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  const filteredAdvertisements = selectedCity === "All"
  ? Advertisement.filter((ad) => ad.propertyType === 'VillasForRent')
  : Advertisement.filter((ad) => ad.propertyType === 'VillasForRent' && ad.selectedCity === selectedCity);

  const sortedAdvertisements = filteredAdvertisements.slice().sort((a, b) => {
    const indexA = Advertisement.indexOf(a);
    const indexB = Advertisement.indexOf(b);
    return indexB - indexA;
  });
  return (
    
    <>
  
         {/* {Ndata.map((val, index) => {
          return (
            <div className='box' key={index}>
              <div className='img'>
                <img src={val.cover} alt='' />
              </div>
              <h4>{val.name}</h4>
              <span>${val.price}</span>
            </div>
          )
        })}  */}

<div className="dropdown-contener">

     

<select
      className="custom-dropdown" // Add your custom styling class here
      value={selectedCity}
      onChange={(e) => handleCityChange(e.target.value)}
    >
      <option value="All">الكل</option>
      <option value="City1">الرياض</option>
      <option value="City2">مكة</option>
      <option value="City3">المدينة المنورة</option>
      <option value="City4">جدة</option>
      <option value="City5">تبوك</option>
      <option value="City6">نجران</option>
      <option value="City7">الطائف</option>
      <option value="City8">ينبع</option>
      <option value="City9">الخبر</option>
      <option value="City10">الدمام</option>
      <option value="City11">حائل</option>
      <option value="City12">الباحة</option>
      <option value="City13">ضباء</option>
      <option value="City14">الأحساء</option>
      <option value="City16">جازان</option>
    </select>
    </div>

    



{sortedAdvertisements.map((ad, index) => (
        <div key={index} className="blog-card">
        <div className="meta">
        <Link to={`/pro/${ad._id}`} state={{ ad }}> 
          <div
            className="photo"
            style={{
                backgroundImage: `url(${ad.avatar})`,
  
              }}
          ></div>
          </Link>
          {/* <ul className="details">
            <li className="author">
              <a href="#">تسنيم</a>
            </li>
            <li className="date">التاريخ</li>
            <li className="tags">
              <ul>
                <li>
                  <a href="#">خيار1</a>
                </li>
                <li>
                  <a href="#">خيار2</a>
                </li>
                <li>
                  <a href="#">خيار3</a>
                </li>
                <li>
                  <a href="#">خيار4</a>
                </li>
              </ul>
            </li>
          </ul> */}
        </div>
        <div className="description" >
          <h2>{ad.Title}</h2>
          <p>
          {ad.price} ريال/سعودي            </p>
          <p className="read-more" >
          <Link to="/pro" > المزيد</Link>
          </p>
        </div>
      </div>
       ))}
    
 
    </>
  )
}


export default VillasForRent
