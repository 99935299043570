import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  BoldLink,
  BoxContainer,
  FormContainer,
  Input,
  LineText,
  MutedLink,
  SubmitButton,
} from "./comman";
import {
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBCheckbox
}
from 'mdb-react-ui-kit';

import { Marginer } from "../marginer";
import { AccountContext } from './AccountContext';
import axios from "axios";
import { GoogleLogin } from 'react-google-login';

export function LoginForm(props) {

  const { switchToSignup } = useContext(AccountContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showErrorBox, setShowErrorBox] = useState(false); 
  const [errorMessage, setErrorMessage] = useState('');
  const [showSuccessBox, setShowSuccessBox] = useState(false);  // New state for success message
  const [successMessage, setSuccessMessage] = useState('');  // New state for success message
  const history = useHistory();

    const google = () => {
      window.open("http://localhost:5000/auth/google", "_self");
    };
  
  

    async function loginUser(event) {
      event.preventDefault();
  
      // Validate if email and password are not empty
      if (!email || !password) {
        setErrorMessage("Please provide both email and password");
        setShowErrorBox(true);
        setShowSuccessBox(false);
        return;
      }
  
      try {
        const response = await fetch('https://aqarukum.com/api/users/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email,
            password,
          }),
        });
  
        const data = await response.json();
  
        if (data.user) {
          localStorage.setItem('token', data.user);
          setSuccessMessage("Login successful!");
          setShowSuccessBox(true);
          setShowErrorBox(false);
          history.push('/');
        } else {
          setErrorMessage("Invalid username or password");
          setShowErrorBox(true);
          setShowSuccessBox(false);
        }
      } catch (error) {
        console.error("Error during login:", error);
        setErrorMessage("An error occurred during login. Please try again.");
        setShowErrorBox(true);
        setShowSuccessBox(false);
      }
    }
  return (
    <BoxContainer>
      <FormContainer onSubmit={loginUser}>
        <Input
          type="email"
          placeholder="البريد الالكتروني"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          type="password"
          placeholder="الرقم السري "
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Marginer direction="vertical" margin={10} />
        <MutedLink href="">  هل نسيت الرقم السري؟</MutedLink>
        <Marginer direction="vertical" margin="1.6em" />
        <SubmitButton type="submit" style={{ backgroundColor: "#008d6a" }}>تسجيل دخول</SubmitButton>

{/* Conditionally render the error box */}
{showErrorBox ? (
  <div style={{ border: '1px solid red', padding: '10px', margin: '10px', borderRadius: '5px', color: 'red' }}>
    {errorMessage}
  </div>
) : null}

{showSuccessBox && (
  <div style={{ border: '1px solid green', padding: '10px', margin: '10px', borderRadius: '5px', color: 'green' }}>
    {successMessage}
  </div>
)}

{/* 
<div className="loginButton google" onClick={google}>
            <img src={Google} alt="" className="icon" /> 
            Google
          </div> */}

<hr className="my-4" />


<MDBBtn className="mb-2 w-100" size="lg" style={{ backgroundColor: '#dd4b39', outline: 'none' }}onClick={google}>
  <MDBIcon fab icon="google" className="mx-2" />
  Sign in with Google
</MDBBtn>

      </FormContainer>
      <Marginer direction="vertical" margin="5px" />
      <LineText>
        ليس لديك حساب؟{" "}
        <BoldLink onClick={switchToSignup} href="#">
          انشاء حساب
        </BoldLink>
      </LineText>
    </BoxContainer>
  );
}
