import React from 'react'
import { Modal, Button, Form } from 'react-bootstrap';

const EditFormModal = ({
    show,
    handleClose,
    handleSaveChanges,
    editedUserName,
    setEditedUserName,
    editedUserEmail,
    setEditedUserEmail,
    editedUserNumber,
    setEditedUserNumber,
    userEmail,
    userName,
    editedFacAccount,
    setEditedFacAccount,
  }
  
  ) => {
    console.log(userEmail)
    return (
        <Modal show={show} onHide={handleClose}>
<Modal.Header closeButton style={{ textAlign: 'right', justifyContent: 'flex-end' }}>
            <Modal.Title>تعديل الملف الشخصي  </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSaveChanges}>
              <Form.Group controlId="formUserName">
                <Form.Label>الاسم  </Form.Label>
                <Form.Control
                  type="text"
              
                  value={editedUserName}
                  onChange={(e) => setEditedUserName(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="formUserEmail">
            <Form.Label>البريد الالكتروني</Form.Label>
       
            <Form.Control
              type="email"
              value={userEmail}

              onChange={(e) => setEditedUserEmail(e.target.value)}
              readOnly
               style={{ cursor: 'not-allowed', backgroundColor: '#f8f9fa' }}

            />
          </Form.Group>
              <Form.Group controlId="formUserNumber">
                <Form.Label>رقم الهاتف </Form.Label>
                <Form.Control
                  type="text"
                  value={editedUserNumber}
                  onChange={(e) => setEditedUserNumber(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="formFacAccount">
                <Form.Label> فيسبوك </Form.Label>
                <Form.Control
                  type="text"
                  value={editedFacAccount}
                  onChange={(e) => setEditedFacAccount(e.target.value)}
                />
              </Form.Group>
              <br/>
              <Button variant="" type="submit" style={{backgroundColor:"#008d6a", color:"#fff"}}>
               حفظ التغيرات
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      );
    };

    export default EditFormModal;