import React, { useEffect, useState } from "react"
import "./Header.css"
import Head from "./Head"
import Search from "./Search"
import Navbar from "./Navbar"

const Header = ({user}) => {



  
  return (
    <>
      <Head />
      <Search user={user}  />
    </>
  )
}

export default Header
