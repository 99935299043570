import React from "react"
import "./style.css"
import images from "../../components/assets/images/2d5ae58f-623a-482c-9d43-9c97e3fad691.jpeg"
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon, MDBBtn, MDBInput } from 'mdb-react-ui-kit';

const Footer = () => {
  return (
    <MDBFooter className='text-center' color='white'  bgColor='#008d6a' >
    <MDBContainer className='p-4'>
      <section className='mb-4'>
        <MDBBtn outline color="light" floating className='m-1' href='#!' role='button'>
          <MDBIcon fab icon='facebook-f' />
        </MDBBtn>

        <MDBBtn outline color="light" floating className='m-1' href='#!' role='button'>
          <MDBIcon fab icon='twitter' />
        </MDBBtn>

        <MDBBtn outline color="light" floating className='m-1' href='#!' role='button'>
          <MDBIcon fab icon='google' />
        </MDBBtn>

        <MDBBtn outline color="light" floating className='m-1' href='#!' role='button'>
          <MDBIcon fab icon='instagram' />
        </MDBBtn>

        <MDBBtn outline color="light" floating className='m-1' href='#!' role='button'>
          <MDBIcon fab icon='linkedin-in' />
        </MDBBtn>

        <MDBBtn outline color="light" floating className='m-1' href='#!' role='button'>
          <MDBIcon fab icon='github' />
        </MDBBtn>
      </section>

      <section className=''>
     
      </section>

      <section className='mb-4'>
        <p>
        مشروع "عقاركم" هو منصة إلكترونية مخصصة لتسهيل عمليات البحث عن عقارات للإيجار والبيع، بالإضافة إلى عروض الأراضي 
        والعقارات الأخرى. تهدف المنصة إلى توفير تجربة سلسة وفعالة للمستخدمين الباحثين عن عقارات للسكن أو الاستثمار

        </p>
      </section>

      
    </MDBContainer>

    <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
    
    
            <MDBCol size="auto">
              <p className='pt-2'>
                <strong> كن جرء منا وانشر في عقاركم</strong>
              </p>
            </MDBCol>
            <MDBCol size="auto">
              <MDBBtn outline color='light' type='submit' className='mb-4'>
                نشر اعلان
              </MDBBtn>
            </MDBCol>
    </div>
  </MDBFooter>
    // <>
    //   <footer>
    //     <div className='container grid2'>
    //       <div className='box'>
    //         <h1 style={{ direction: 'rtl' }}>عقاركم</h1>
    //         <img src={images} style={{ width: '300px',height:'200px'  }}/>
    //         <br/>
    //         <br/>
    //         <div className='icon d_flex'>
    //           <div className='img d_flex'>
    //             <i class='fa-brands fa-google-play'></i>
    //             <span>Google Play</span>
    //           </div>
    //           <div className='img d_flex'>
    //             <i class='fa-brands fa-app-store-ios'></i>
    //             <span>App Store</span>
    //           </div>
    //         </div>
    //       </div>

    //       <div className='box' style={{ direction: 'rtl' }}>
    //         <h2>من نحن</h2>
    //         <ul>
    //           <li>Careers</li>
    //           <li>Our Stores</li>
    //           <li>Our Cares</li>
    //           <li>Terms & Conditions</li>
    //           <li>Privacy Policy</li>
    //         </ul>
    //       </div>
    //       <div className='box' style={{ direction: 'rtl' }}>
    //         <h2> </h2>
    //         <ul>
    //           {/* <li>Help Center </li>
    //           <li>How to Buy </li>
    //           <li>Track Your Order </li>
    //           <li>Corporate & Bulk Purchasing </li>
    //           <li>Returns & Refunds </li> */}
    //         </ul>
    //       </div>
    //       <div className='box' style={{ direction: 'rtl' }}>
    //         <h2> تواصل معنا</h2>
    //         <ul>
    //           <li>   :البريدالكتروني </li>
    //           <li>هاتف: +1 1123 456 780</li>
    //           <li>       <a href='https://twitter.com/aqarukum57525' target='_blank' rel='noopener noreferrer' style={{ color: "#FFF" }}>
    //             إكس
    //           </a></li>

    //         </ul>
    //       </div>
    //     </div>
    //   </footer>
    // </>


    
  )
}

export default Footer
