import React from 'react'
import Product from '../product/Product'

const AdvertisementDetailsModal = ({ advertisementDetails, handleClose }) => {
    return (
        <div>
        {/* Display the details of the selected advertisement */}
        {advertisementDetails && (
        <>
        <Product/>
            {/* ... (other details) */}
        </>
        )}
        {/* ... (existing code) */}
    </div>
  )
}

export default AdvertisementDetailsModal