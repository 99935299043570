


// MultiStepForm.js
import React, { useEffect, useState } from 'react';
import './MultiStepForm.css';
import { Button, Form, FormCheckLabel, FormControl, FormLabel } from 'react-bootstrap';
import { Checkbox, FormControlLabel, RadioGroup ,FormGroup} from '@mui/material';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

import Slider from '@mui/material/Slider';
import Radio from '@mui/material/Radio';
import renderApartmentForm from './renderApartmentForm ';
import RenderLandForm from './RenderLandForm';
import { useLocation, useHistory } from "react-router-dom";
import { jwtDecode } from 'jwt-decode';
import Modal from 'react-modal';

const MultiStepForm = ({user}) => {
  const [step, setStep] = useState(1);
  const [streetWidth, setStreetWidth] = useState(20);
  const [showMap, setShowMap] = useState(false);
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');


  
  const handleLocationChange = (newLatitude, newLongitude) => {
    setLatitude(newLatitude);
    setLongitude(newLongitude);
  };

  const handleOpenMap = () => {
    setShowMap(true);
  };

  const handleCloseMap = () => {
    setShowMap(false);
  };

  // Step 1 state
  const [image, setimage] = useState([]);

  // Step 2 state
  const [numPlanned, setNumPlanned] = useState('');
  const [numPiece, setNumPiece] = useState('');
  const [showPlannedInfo, setShowPlannedInfo] = useState(false);
  const [Space, setSpace] = useState('');
  const [Title, setTitle] = useState('');
  const [Numberwells, setNumberwells] = useState('');
  const [  Numbertrees, setNumbertrees] = useState('');

  // Step 3 state
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');
  // Step 5 state
  const [AdvertiserName, setAdvertiserName] = useState('');
  const [AdvertiserNum, setAdvertiserNum] = useState('');
  const [countryCode, setCountryCode] = useState('+'); // You can set the default country code

  // Step 4 state
  const [Width, setWidth] = useState('');
  const [Bathrooms, setBathrooms] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedPayment, setSelectedPayment] = useState('');
  const [floorNum, setFloorNum] = useState('');
  const [blockNumber, setBlockNumber] = useState('');
  const [furnished, setFurnished] = useState(false);
  const [annex, setAnnex] = useState(false);
  const [kitchen, setKitchen] = useState(false);
  const [carEntrance, setCarEntrance] = useState(false);
  const [elevator, setElevator] = useState(false);
  const [airConditioning, setAirConditioning] = useState(false);
  const [waterHeater, setWaterHeater] = useState(false);
  const [electricityAvailability, setElectricityAvailability] = useState(false);
  const [privateRoof, setPrivateRoof] = useState(false);
  const [inVilla, setInVilla] = useState(false);
  const [privateEntrance, setPrivateEntrance] = useState(false);
  const [twoEntrances, setTwoEntrances] = useState(false);
  const [floorNumber, setFloorNumber] = useState(1);
  const [roomCount, setRoomCount] = useState(1);
  const [propertyType, setPropertyType] = useState(''); // Default property type
  const [selectedCity, setSelectedCity] = useState("All"); 
  const [canProceed, setCanProceed] = useState(false); 
  const [tokenDecoded, setTokenDecoded] = useState(false);

  const [AdvertiserEmail, setAdvertiserEmail] = useState('');

  const [Numberapartments, setNumberapartments] = useState('');
  const [Numberstores, setNumberstores] = useState('');
  const [Accommodationtype, setAccommodationtype] = useState('');
  console.log("totiiifrute", user);

  const totifrute = user && user.emails && user.emails[0]?.value ? user.emails[0].value : 'Default Email';
  
 // console.log("print totifrute", totifrute);
  
  const [showSuccessModal, setShowSuccessModal] = useState('');
  
  useEffect(() => {
    const getAuthToken = localStorage.getItem('token');
    const decodedToken = getAuthToken ? jwtDecode(getAuthToken) : null;
    console.log('Decoded Token:', decodedToken);
  
    let AdvertiserEmail;
  
    if (decodedToken) {
      setTokenDecoded(true);
      AdvertiserEmail = decodedToken.email;
    } else {
      AdvertiserEmail = totifrute;
      console.log("first",AdvertiserEmail)
    }
  
    setAdvertiserEmail(AdvertiserEmail);
  }, []);
  
  console.log("totiiiii", AdvertiserEmail);
  

  

  const handleChange = (e) => {
    e.preventDefault();

    if (e.target.type === 'file') {
      const selectedFiles = e.target.files;
      setimage(selectedFiles);
    } else {
      // Handle other form input changes if needed
    }
  };
  
  const getAuthToken = localStorage.getItem('token');
  const decodedToken = getAuthToken ? jwtDecode(getAuthToken) : null;

  // Now you can access properties from decodedToken, such as userId
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    

    for (let i = 0; i < image.length; i++) {
      formData.append('image', image[i]);
    }


    // formData.append('image', image);
    formData.append('streetWidth', streetWidth);
    formData.append('AdvertiserNum', AdvertiserNum);
    formData.append('AdvertiserName', AdvertiserName);
    formData.append('propertyType', propertyType);
    formData.append('numPlanned', numPlanned);
    formData.append('roomCount', roomCount);
    formData.append('floorNumber', floorNumber);
    formData.append('twoEntrances', twoEntrances);
    formData.append('privateEntrance', privateEntrance);
    formData.append('inVilla', inVilla);
    formData.append('privateRoof', privateRoof);
    formData.append('electricityAvailability', electricityAvailability);
    formData.append('waterHeater', waterHeater);
    formData.append('airConditioning', airConditioning);
    formData.append('elevator', elevator);
    formData.append('kitchen', kitchen);
    formData.append('annex', annex);
    formData.append('furnished', furnished);
    formData.append('blockNumber', blockNumber);
    formData.append('floorNum', floorNum);
    formData.append('selectedPayment', selectedPayment);
    formData.append('selectedStatus', selectedStatus);
    formData.append('Width', Width);
    formData.append('description', description);
    formData.append('price', price);
    formData.append('showPlannedInfo', showPlannedInfo);
    formData.append('numPiece', numPiece);
    formData.append('AdvertiserEmail', AdvertiserEmail);
    formData.append('Numberapartments', Numberapartments);
    formData.append('Numberstores', Numberstores);
    formData.append('Accommodationtype', Accommodationtype);
    formData.append('Space', Space);
    formData.append('carEntrance', carEntrance);
    formData.append('Title', Title);
    formData.append('Numberwells', Numberwells);
    formData.append('Numbertrees', Numbertrees);
    formData.append('selectedCity', selectedCity);

   

    console.log(AdvertiserEmail)

    try {
      const response = await fetch('https://aqarukum.com/api/users/addadvertisement', {
        method: 'POST',
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${getAuthToken}`,

        },
        body: formData,
      });

      if (response.ok) {
        console.log('Form submitted successfully!');
        setShowSuccessModal(true); // Set the state to show the success modal
        console.log('showSuccessModal value:', showSuccessModal);
        alert('شكرًا لنشر الإعلان. يمكنك مشاهدة الإعلان الخاص بك على الصفحة الرئيسية.')
        window.location.href = '/';
      
        // Handle redirection here
      } else {
        console.error('Form submission failed.');
        alert('حدث خطأ ما .. الرجاء المحاولة في وقت اخر');
      }
    } catch (error) {
      console.error('Error submitting the form:', error);
    }
  };

  const handleAccommodationTypeCheckboxChange = (value) => {
    // Check if the value is already selected
    const isSelected = Accommodationtype.includes(value);

    // Update the selected options based on the current state
    if (isSelected) {
      setAccommodationtype((prevSelected) =>
        prevSelected.filter((type) => type !== value)
      );
    } else {
      setAccommodationtype((prevSelected) => [...prevSelected, value]);
    }
  };

  const handlePropertyTypeChange = (e) => {
    setPropertyType(e.target.value);
  };
  const marks = [
    { value: 0, label: '1م' },
    { value: 5, label: '3م' },
    { value: 10, label: '5م' },
    { value: 15, label: '6م' },
  ];
  const roomMarks = [
    { value: 0, label: '1' },
    { value: 5, label: '5' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
  ];
  const floorMarks = [
    { value: 0, label: '1' },
    { value: 5, label: '5' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
  ];

    // const handleNext = () => {
    //   setStep((prevStep) => prevStep + 1);
    // };
    const handleNext = () => {
      // Check if all required fields are selected
      if (step === 1) {
        // Check if the image is not selected
        if (!image || image.length === 0) {
          // Alert the user or handle the validation as needed
          alert("يرجى تحميل صورة قبل المتابعة إلى الخطوة التالية.");
        
          return;
        }
    
        // Check other conditions (city and propertyType)
        if (!selectedCity || propertyType === "ch") {
          // Alert the user or handle the validation as needed
          alert("يرجى تحديد المدينة ونوع العقار قبل المتابعة إلى الخطوة التالية.");
          // Do not proceed to the next step
          return;
        }
      }
    
      setStep(step + 1);
    };
    
    
    

  const handlePrevious = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleStreetWidthChange = (event, newValue) => {
    setStreetWidth(newValue);
  };

  const handleRadioChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handlePaymentRadioChange = (event) => {
    setSelectedPayment(event.target.value);
  };
  const handleAccommodationtypetRadioChange = (event) => {
    setAccommodationtype(event.target.value);
  };
  function valuetext(value ) {
    return `${value}`;
  }
  
  // function valuetext(value: number | number[]) {
  //   return `${value}`;
  // }
  
  const renderPropertyTypeForm = () => {
    switch (propertyType) {
      case 'land':
      case 'WarehousesForRent':
      case 'VillasForRent':
      case 'VillasForSale':
      case 'ShopsForRent':
      case 'BuildingsForSale':

        if (step == 2) {
          return (
            <>
              <h2 className="fs-title">الخطوة الثانية</h2>
              <h3 className="fs-subtitle">تفاصيل القطعة</h3>
              <input type="text" name="NumPlanned" placeholder="رقم المخطط" onChange={(e) => setNumPlanned(e.target.value)} />
              <input type="text" name="NumPiece" placeholder="رقم القطعة" onChange={(e) => setNumPiece(e.target.value)} />
              <FormControlLabel
                style={{ textAlign: 'left', marginLeft: '0', width: '100%' }}
                required
                control={<Checkbox checked={showPlannedInfo} onChange={() => setShowPlannedInfo(!showPlannedInfo)} />}
                label="اظهار معلومات المخطط ضمن الاعلان"
              />
              
  
          </>
          );
        }
        if (step == 3) {
          return (
            <>
              <h2 className="fs-title">الحطوة الثالثة</h2>
              <h3 className="fs-subtitle">ادخل تفاصيل الاعلان</h3>
              <input type="text" name="price" placeholder="السعر الاجمالي" onChange={(e) => setPrice(e.target.value)} />
              <input type="text" name="Title" value={Title} placeholder=" العنوان" onChange={(e) => setTitle(e.target.value)} />
              <textarea
                rows="9"
                cols="50"
                placeholder="وصف العقار"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                style={{ resize: 'vertical', height: 250, }}
              />
            </>
          );
        }
        if (step == 4) {
          return (
            <>
              <h2 className="fs-title">الخطوة الرابعة</h2>
              <h3 className="fs-subtitle">ادخل تفاصيل الاعلان</h3>
              <div className="form-group">
                <label className="slider-label">عرض الشارع: {Width} م</label>
                <input type="number" name="Width" placeholder="عرض الشارع"  value={Width}  onChange={(e) => setWidth(e.target.value)} />


              </div>
              <div className="form-group">
                <label className="slider-label"> المساحة: {Space} م</label>
                <input type="number" name="Width" placeholder="المساحة"  value={Space} onChange={(e) => setSpace(e.target.value)} />


              </div>

              <RadioGroup row aria-labelledby="payment-label" name="payment" value={selectedPayment} onChange={handlePaymentRadioChange}>
                <FormControlLabel value="Monthly" control={<Radio />} label="يومي" />
                <FormControlLabel value="daily" control={<Radio />} label="شهري" />
                <FormControlLabel value="annual" control={<Radio />} label="سنوي" />
              </RadioGroup>
              <FormGroup aria-labelledby="payment-label" row>
         
      <FormControlLabel
        control={
          <Checkbox
            checked={Accommodationtype.includes('commercial')}
            onChange={() => handleAccommodationTypeCheckboxChange('commercial')}
          />
        }
        label="تجاري"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={Accommodationtype.includes('Residential')}
            onChange={() => handleAccommodationTypeCheckboxChange('Residential')}
          />
        }
        label="سكني"
      />
              </FormGroup>
              {/* <div className="form-group">
              <label className="slider-label"> العرض: {streetWidth} م</label>
              <Slider
                aria-label="Custom marks"
                
                value={streetWidth}
                onChange={handleStreetWidthChange}
                getAriaValueText={(value) => `${value}م`}
                step={10}
                valueLabelDisplay="auto"
                marks={marks}
                style={{ color: '#008d6a' }} 
             
              />
            </div> */}

              {/* <input type="number" name="FloorNum"  placeholder="رقم الدور"   onChange={(e) => setFloorNum(e.target.value)} /> */}
              <input type="number" name="BlockNumber" placeholder="عمر العقار" value={blockNumber} onChange={(e) => setBlockNumber(e.target.value)} />

              <input type="number" name="BlockNumber" placeholder=" عدد المحلات" value={Numberstores} onChange={(e) => setNumberstores(e.target.value)} />
              <input type="number" name="BlockNumber" placeholder="عدد الشقق " value={Numberapartments}  onChange={(e) => setNumberapartments(e.target.value)} />


              <div className="radio-container">
                <FormControlLabel
                  control={<Checkbox checked={furnished} onChange={() => setFurnished(!furnished)} />}
                  label="مؤثثة"


                />
              </div>
              <hr />
              <div className="radio-container">
                <FormControlLabel
                  control={<Checkbox checked={annex} onChange={() => setAnnex(!annex)} />}
                  label="ملحق"
                />
              </div>
              <hr />

              <div className="radio-container">
                <FormControlLabel
                  control={<Checkbox checked={kitchen} onChange={() => setKitchen(!kitchen)} />}
                  label="مطبخ"
                />
              </div>

              <hr />


              <div className="radio-container">
                <FormControlLabel
                  control={<Checkbox checked={carEntrance} onChange={() => setCarEntrance(!carEntrance)} />}
                  label="مدخل سيارات"
                />
              </div>

              <hr />
              <div className="radio-container">
                <FormControlLabel
                  control={<Checkbox checked={airConditioning} onChange={() => setAirConditioning(!airConditioning)} />}
                  label="مكيفات "

                />
              </div>

              <hr />
              <div className="radio-container">
                <FormControlLabel
                  control={<Checkbox checked={waterHeater} onChange={() => setWaterHeater(!waterHeater)} />}
                  label="توفر مياه "

                />
              </div>

              <hr />
              <div className="radio-container">
                <FormControlLabel
                  control={<Checkbox checked={electricityAvailability} onChange={() => setElectricityAvailability(!electricityAvailability)} />}
                  label="توفر الكهرباء "

                />
              </div>

              <hr />
              <div className="radio-container">
                <FormControlLabel
                  control={<Checkbox checked={privateRoof} onChange={() => setPrivateRoof(!privateRoof)} />}
                  label="سطح خاص  "

                />
              </div>
              <hr />
              <div className="radio-container">
                <FormControlLabel
                  control={<Checkbox checked={inVilla} onChange={() => setInVilla(!inVilla)} />}
                  label="في فيلا  "


                />
              </div>
              <hr />

              <div className="radio-container">
                <FormControlLabel
                  control={<Checkbox checked={privateEntrance} onChange={() => setInVilla(!privateEntrance)} />}
                  label=" مدخل خاص  "


                />
              </div>
              <hr />
              <div className="radio-container">
                <FormControlLabel
                  control={<Checkbox checked={twoEntrances} onChange={() => setTwoEntrances(!twoEntrances)}
                  />}
                  label="  مدخلين  "


                />
              </div>
              <hr />
              {/*   
      <div className="form-group">
        <label className="slider-label">الطابق: {floorNumber}</label>
        <Slider
          aria-label="Custom marks"
          value={floorNumber}
          onChange={(event, newValue) => setFloorNumber(newValue)}
          getAriaValueText={valuetext}
          step={1}
          valueLabelDisplay="15"
          marks={floorMarks}
          max={15} // Set the maximum value to 15
          style={{ color: '#008d6a' }} 

        />
        </div> */}
            </>
          );
        }

      case 'FarmsForSale':

        if (step == 2) {
          return (
            <>
              <h2 className="fs-title">الخطوة الثانية</h2>
              <h3 className="fs-subtitle">تفاصيل القطعة</h3>
              <input type="text" name="NumPlanned" placeholder="رقم المخطط" onChange={(e) => setNumPlanned(e.target.value)} />
              <input type="text" name="NumPiece" placeholder="رقم القطعة" onChange={(e) => setNumPiece(e.target.value)} />
              <FormControlLabel
                style={{ textAlign: 'left', marginLeft: '0', width: '100%' }}
                required
                control={<Checkbox checked={showPlannedInfo} onChange={() => setShowPlannedInfo(!showPlannedInfo)} />}
                label="اظهار معلومات المخطط ضمن الاعلان"
              />
            </>
          );
        }
        if (step == 3) {
          return (
            <>
              <h2 className="fs-title">الحطوة الثالثة</h2>
              <h3 className="fs-subtitle">ادخل تفاصيل الاعلان</h3>
              <input type="text" name="price" placeholder="السعر الاجمالي" onChange={(e) => setPrice(e.target.value)} />
              <input type="text" name="Title" value={Title} placeholder=" العنوان" onChange={(e) => setTitle(e.target.value)} />
              <textarea
                rows="9"
                cols="50"
                placeholder="وصف العقار"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                style={{ resize: 'vertical', height: 250, }}
              />
            </>
          );
        }
        if (step == 4) {
          return (
            <>
              <h2 className="fs-title">الخطوة الرابعة</h2>
              <h3 className="fs-subtitle">ادخل تفاصيل الاعلان</h3>
              <div className="form-group">
                <label className="slider-label">عرض الشارع: {Width} م</label>
                <input type="number" name="Width" placeholder="عرض الشارع" onChange={(e) => setWidth(e.target.value)} />


              </div>
              <div className="form-group">
                <label className="slider-label"> المساحة: {Space} م²</label>
                <input type="number" name="Width" placeholder="المساحة" onChange={(e) => setSpace(e.target.value)} />


              </div>
              <div className="form-group">
                <label className="slider-label"> عدد الابار: {Numberwells} </label>
                <input type="number" name="Numberwells" placeholder="عدد الابار" value={Numberwells} onChange={(e) => setNumberwells(e.target.value)} />


              </div>
              <div className="form-group">
                <label className="slider-label"> عددالاشجار: {Numbertrees} </label>
                <input type="number" name="Numbertrees" placeholder="عدد الاشجار"  value={Numbertrees} onChange={(e) => setNumbertrees(e.target.value)} />


              </div>
             

              <RadioGroup row aria-labelledby="payment-label" name="payment" value={selectedPayment} onChange={handlePaymentRadioChange}>
                <FormControlLabel value="Monthly" control={<Radio />} label="يومي" />
                <FormControlLabel value="daily" control={<Radio />} label="شهري" />
                <FormControlLabel value="annual" control={<Radio />} label="سنوي" />
              </RadioGroup>

              <div className="form-group">
      <label className="slider-label">عدد الغرف: {roomCount}</label>
      <Slider
        aria-label="Custom marks"
        value={roomCount}
        onChange={(event, newValue) => setRoomCount(newValue)}
        getAriaValueText={valuetext}
        step={1}
        valueLabelDisplay="auto"
        marks={roomMarks}
        max={15} // Set the maximum value to 15
        style={{ color: '#008d6a' }} 

      />
    </div>

              {/* <input type="number" name="FloorNum"  placeholder="رقم الدور"   onChange={(e) => setFloorNum(e.target.value)} /> */}
              <input type="number" name="BlockNumber" placeholder="عمر العقار" onChange={(e) => setBlockNumber(e.target.value)} />


              <div className="radio-container">
                <FormControlLabel
                  control={<Checkbox checked={waterHeater} onChange={() => setWaterHeater(!waterHeater)} />}
                  label="توفر مياه "

                />
              </div>

              <hr />
              <div className="radio-container">
                <FormControlLabel
                  control={<Checkbox checked={electricityAvailability} onChange={() => setElectricityAvailability(!electricityAvailability)} />}
                  label="توفر الكهرباء "

                />
              </div>

              <hr />



              {/*   
      <div className="form-group">
        <label className="slider-label">الطابق: {floorNumber}</label>
        <Slider
          aria-label="Custom marks"
          value={floorNumber}
          onChange={(event, newValue) => setFloorNumber(newValue)}
          getAriaValueText={valuetext}
          step={1}
          valueLabelDisplay="15"
          marks={floorMarks}
          max={15} // Set the maximum value to 15
          style={{ color: '#008d6a' }} 

        />
        </div> */}
            </>
          );
        }




      case 'apartment':

      case 'ApartmentsForSale':
      case 'FurnishedApartments':

        if (step == 2) {
          return (
            <>
              <h2 className="fs-title">الخطوة الثانية</h2>
              <h3 className="fs-subtitle">تفاصيل القطعة</h3>
              <input type="text" name="NumPlanned" placeholder="رقم المخطط" onChange={(e) => setNumPlanned(e.target.value)} />
              <input type="text" name="NumPiece" placeholder="رقم القطعة" onChange={(e) => setNumPiece(e.target.value)} />
              <FormControlLabel
                style={{ textAlign: 'left', marginLeft: '0', width: '100%' }}
                required
                control={<Checkbox checked={showPlannedInfo} onChange={() => setShowPlannedInfo(!showPlannedInfo)} />}
                label="اظهار معلومات المخطط ضمن الاعلان"
              />
            </>
          );
        }
        if (step == 3) {
          return (
            <>
              <h2 className="fs-title">الحطوة الثالثة</h2>
              <h3 className="fs-subtitle">ادخل تفاصيل الاعلان</h3>
              <input type="text" name="price" placeholder="السعر الاجمالي" value={price} onChange={(e) => setPrice(e.target.value)} />
              <input type="text" name="Title" value={Title} placeholder=" العنوان" onChange={(e) => setTitle(e.target.value)} />

              <textarea type="text" name="description" placeholder="وصف العقار" value={description} onChange={(e) => setDescription(e.target.value)} />
            </>
          );
        }
        if (step == 5) {
          return (
            <>
              <h2 className="fs-title">الحطوة الخامسة</h2>
              <h3 className="fs-subtitle">ادخل تفاصيل المعلن</h3>
              <input type="text" name="AdvertiserName" placeholder="اسم الملعن " onChange={(e) => setAdvertiserName(e.target.value)} />
              <input
                type="text"
                name="AdvertiserNum"
                placeholder="رقم المعلن "
                onChange={(e) => setAdvertiserNum(`${countryCode}${e.target.value}`)}
              />                </>
          );
        }
        if (step == 4) {
          return (
            <>
              <h2 className="fs-title">الخطوة الرابعة</h2>
              <h3 className="fs-subtitle">ادخل تفاصيل الاعلان</h3>
              <div className="form-group">
                <label className="slider-label">عرض الشارع: {Width} م</label>
                <input type="number" name="Width" placeholder="عرض الشارع" onChange={(e) => setWidth(e.target.value)} />
                <div className="form-group">
                  <label className="slider-label"> المساحة: {Space} م²</label>
                  <input type="number" name="Width" placeholder="المساحة" onChange={(e) => setSpace(e.target.value)} />


                </div>
                {/* <Slider

              aria-label="Custom marks"
              value={streetWidth}
              onChange={handleStreetWidthChange}
              getAriaValueText={(value) => `${value}م`}
              step={10}
              valueLabelDisplay="auto"
              marks={marks}
            /> */}
              </div>
              <RadioGroup row aria-labelledby="status-label" name="status" value={selectedStatus} onChange={handleRadioChange}>
                <FormControlLabel value="single" control={<Radio />} label="عزاب" />
                <FormControlLabel value="married" control={<Radio />} label="عوائل" />
              </RadioGroup>
              <RadioGroup row aria-labelledby="payment-label" name="payment" value={selectedPayment} onChange={handlePaymentRadioChange}>
                <FormControlLabel value="Monthly" control={<Radio />} label="يومي" />
                <FormControlLabel value="daily" control={<Radio />} label="شهري" />
                <FormControlLabel value="annual" control={<Radio />} label="سنوي" />
              </RadioGroup>
              <div className="form-group">
                {/* <label className="slider-label">عرض الصالات: {streetWidth} </label>

            <input type="number" name="streetWidth" 
            placeholder=" عرض الصالات"   value={streetWidth} onChange={(e) =>setStreetWidth(e.target.value)} /> */}

                {/* <Slider
              aria-label="Custom marks"
              value={streetWidth}
              onChange={handleStreetWidthChange}
              getAriaValueText={(value) => `${value}م`}
              step={10}
              valueLabelDisplay="auto"
              marks={floorMarks}
              max={15} // Set the maximum value to 15
              style={{ color: '#008d6a' }} 

            /> */}
              </div>
              <div className="form-group">
                <label className="slider-label">دورات المياه: {Bathrooms} </label>
                <input type="number" name="streetWidth"
                  placeholder="دورات المياه:" value={Bathrooms} onChange={(e) => setBathrooms(e.target.value)} />



              </div>
              <input type="number" name="FloorNum" placeholder="رقم الدور" onChange={(e) => setFloorNum(e.target.value)} />
              <input type="number" name="BlockNumber" placeholder="عمر العقار" onChange={(e) => setBlockNumber(e.target.value)} />


              <div className="radio-container">
                <FormControlLabel
                  control={<Checkbox checked={furnished} onChange={() => setFurnished(!furnished)} />}
                  label="مؤثثة"


                />
              </div>
              <hr />
              <div className="radio-container">
                <FormControlLabel
                  control={<Checkbox checked={annex} onChange={() => setAnnex(!annex)} />}
                  label="ملحق"
                />
              </div>
              <hr />
              <div className="radio-container">
                <FormControlLabel
                  control={<Checkbox checked={elevator} onChange={() => setElevator(!elevator)} />}
                  label="مصعد"
                />
              </div>
              <hr />

              <div className="radio-container">
                <FormControlLabel
                  control={<Checkbox checked={kitchen} onChange={() => setKitchen(!kitchen)} />}
                  label="مطبخ"
                />
              </div>

              <hr />

              <div className="radio-container">
                <FormControlLabel
                  control={<Checkbox checked={carEntrance} onChange={() => setCarEntrance(!carEntrance)} />}
                  label="مدخل سيارات"
                />
              </div>

              <hr />
              <div className="radio-container">
                <FormControlLabel
                  control={<Checkbox checked={airConditioning} onChange={() => setAirConditioning(!airConditioning)} />}
                  label="مكيفات "

                />
              </div>

              <hr />
              <div className="radio-container">
                <FormControlLabel
                  control={<Checkbox checked={waterHeater} onChange={() => setWaterHeater(!waterHeater)} />}
                  label="توفر مياه "

                />
              </div>

              <hr />
              <div className="radio-container">
                <FormControlLabel
                  control={<Checkbox checked={electricityAvailability} onChange={() => setElectricityAvailability(!electricityAvailability)} />}
                  label="توفر الكهرباء "

                />
              </div>

              <hr />
              <div className="radio-container">
                <FormControlLabel
                  control={<Checkbox checked={privateRoof} onChange={() => setPrivateRoof(!privateRoof)} />}
                  label="سطح خاص  "

                />
              </div>

              <hr />
              <div className="radio-container">
                <FormControlLabel
                  control={<Checkbox checked={inVilla} onChange={() => setInVilla(!inVilla)} />}
                  label="في فيلا  "


                />
              </div>
              <hr />
              <div className="radio-container">
                <FormControlLabel
                  control={<Checkbox checked={privateEntrance} onChange={() => setPrivateEntrance(!privateEntrance)} />}
                  label=" مدخل خاص  "


                />
              </div>
              <hr />
              <div className="radio-container">
                <FormControlLabel
                  control={<Checkbox checked={twoEntrances} onChange={() => setTwoEntrances(!twoEntrances)}
                  />}
                  label="  مدخلين  "


                />
              </div>
              <hr />

              <div className="form-group">
                <label className="slider-label">عدد الغرف: {roomCount}</label>
                <Slider
                  aria-label="Custom marks"
                  value={roomCount}
                  onChange={(event, newValue) => setRoomCount(newValue)}
                  getAriaValueText={valuetext}
                  step={1}
                  valueLabelDisplay="auto"
                  marks={roomMarks}
                  max={15} // Set the maximum value to 15
                  style={{ color: '#008d6a' }}

                />
              </div>
              {/* 
    <div className="form-group">
      <label className="slider-label">الطابق: {floorNumber}</label>
      <Slider
        aria-label="Custom marks"
        value={floorNumber}
        onChange={(event, newValue) => setFloorNumber(newValue)}
        getAriaValueText={valuetext}
        step={1}
        valueLabelDisplay="auto"
        marks={floorMarks}
        max={15} // Set the maximum value to 15
        style={{ color: '#008d6a' }} 

      />
      </div> */}
            </>
          );
        }







      // Add cases for other property types
      default:
        return null;
    }
  };
  return (



    <div id="msform" >
      <form onSubmit={handleSubmit} >

        {step === 1 && (
          <fieldset>
            <h2 className="fs-title">إرفاق صور</h2>
            <h3 className="fs-subtitle">الخطوة الأولى</h3>
            <Form.Group controlId="formGridFile" className="col col-sm-6">
              <Form.Label  className="required-field" >إرفاق صورة</Form.Label>
              <Form.Control type="file" name="image" onChange={(e) => setimage(e.target.files)} multiple required  />
            </Form.Group>
            <Form.Group id="propertyType">
              <Form.Label  className="required-field">نوع العقار</Form.Label>
              <Form.Control as="select" value={propertyType} name="propertyType" onChange={(e) => setPropertyType(e.target.value)} >
                <option value="ch"> اختر</option>
                <option value="land">أرض للبيع</option>
                <option value="apartment">شقة للايجار </option>
                <option value="FurnishedApartments">شقة مفروشة</option>
                <option value="WarehousesForRent">مستودع للإيجار</option>
                <option value="VillasForRent">استراحة للإيجار</option>
                <option value="FarmsForSale">مزرعة للبيع</option>
                <option value="VillasForSale">استراحة للبيع</option>
                <option value="ShopsForRent">محلات للإيجار</option>
                <option value="BuildingsForSale">عمائر للبيع</option>
                <option value="ApartmentsForSale">شقة للبيع</option>

              </Form.Control>
            </Form.Group>
            
            <Form.Group id="City">
              <Form.Label  className="required-field">المدينة</Form.Label>
              <Form.Control as="select" value={selectedCity} name="selectedCity" onChange={(e) => setSelectedCity(e.target.value)} >
              <option value="">اختر </option>
        <option value="City1"> الرياض</option>
        <option value="City2">مكة </option>
        <option value="City3"> المدينة المنورة </option>
        <option value="City4"> جدة</option>
   
        <option value="City5">تبوك </option>
   
        <option value="City6"> نجران</option>
   
        <option value="City7"> الطائف</option>
   
        <option value="City8">ينبع </option>
   
        <option value="City9"> الخبر</option>
        <option value="City10"> الدمام</option>
        <option value="City11"> حائل</option>
        <option value="City12"> الباحة</option>
        <option value="City13"> ضباء</option>
        <option value="City14"> الأحساء</option>
        <option value="City16"> جازان</option>
        <option value="All">الكل </option>

              </Form.Control>
            </Form.Group>

            <input type="button" name="next" className="next action-button" value="التالي" onClick={handleNext} />
    
          </fieldset>

          
        )}

        {step === 2 && (
          <fieldset>
            {renderPropertyTypeForm()}

            {/* <FormControlLabel
            style={{ textAlign: 'left', marginLeft: '0', width: '100%' }}
            required
            control={<Checkbox checked={showPlannedInfo} onChange={() => setShowPlannedInfo(!showPlannedInfo)} />}
            label="اظهار معلومات المخطط ضمن الاعلان"
          /> */}
            <input type="button" name="previous" className="previous action-button" value="السابق" onClick={handlePrevious} />
            <input type="button" name="next" className="next action-button" value="التالي" onClick={handleNext} />
          </fieldset>
        )}

        {step === 3 && (
          <fieldset>
            {renderPropertyTypeForm()}

            <input type="button" name="previous" className="previous action-button" value="السابق" onClick={handlePrevious} />
            <input type="button" name="next" className="next action-button" value="التالي" onClick={handleNext} />
          </fieldset>
        )}
        {step === 4 && (
          <fieldset>
            {renderPropertyTypeForm()}

            <input type="button" name="previous" className="previous action-button" value="السابق" onClick={handlePrevious} />
            <input type="button" name="next" className="next action-button" value="التالي" onClick={handleNext} />
          </fieldset>
        )}

        {step === 5 && (
          <fieldset>
            {renderPropertyTypeForm()}


            <input type="button" name="previous" className="previous action-button" value="السابق" onClick={handlePrevious} />
            <button type="submit" className="submit action-button">
              نشر الأعلان
            </button>          {/* <a href="https://twitter.com/GoktepeAtakan" className="submit action-button" target="_top" onSubmit={handleSubmit}>
            Submit
          </a> */}
          </fieldset>
        )}
        {/* Step 6: Success Modal */}
        {showSuccessModal && (
          <Modal show={true} onHide={() => setShowSuccessModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>تم نشر الإعلان بنجاح!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              شكرًا لنشر الإعلان. يمكنك مشاهدة الإعلان الخاص بك على الصفحة الرئيسية.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={() => setShowSuccessModal(false)}>
                إغلاق
              </Button>
            </Modal.Footer>
          </Modal>
        )}

      </form>

    </div>

  );
};

export default MultiStepForm;


const LocationMap = ({ onLocationChange }) => {
  const [position, setPosition] = useState([0, 0]);

  const handleMapClick = (e) => {
    const { lat, lng } = e.latlng;
    setPosition([lat, lng]);
    onLocationChange(lat, lng);
  };

  return (
    <MapContainer center={position} zoom={13} style={{ height: '400px', width: '100%' }} onClick={handleMapClick}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={position}>
        <Popup>Your chosen location</Popup>
      </Marker>
    </MapContainer>
  );
};