// import React, { useState, useEffect } from 'react';
// import {
//   MDBCol,
//   MDBContainer,
//   MDBRow,
//   MDBCard,
//   MDBCardText,
//   MDBCardBody,
//   MDBCardImage,
//   MDBBtn,
//   MDBListGroup,
//   MDBListGroupItem,
//   MDBIcon,
// } from 'mdb-react-ui-kit';
// import { jwtDecode } from 'jwt-decode';

// const Profile = () => {
//   const [userAdvertisements, setUserAdvertisements] = useState([]);
//   const [userEmail, setUserEmail] = useState('');
//   const [userName, setUserName] = useState('');
//   const [userNumber, setUserNumber] = useState('');

//   useEffect(() => {
//     fetchUserAdvertisements();
//   }, []);

//   const fetchUserAdvertisements = async () => {
//     try {
//       const response = await fetch('https://aqarukum.com/api/users/allAdvertisementu', {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${localStorage.getItem('token')}`,
//         },
//       });

//       if (response.ok) {
//         const data = await response.json();
//         setUserAdvertisements(data);
//         const decodedToken = jwtDecode(localStorage.getItem('token'));
//         setUserEmail(decodedToken.email);
//         setUserName(decodedToken.name);
//       } else {
//         console.error('Failed to fetch user advertisements');
//       }
//     } catch (error) {
//       console.error('Error fetching user advertisements:', error);
//     }
//   };

//   const handleShowNumber = async () => {
//     try {
//       const response = await fetch('https://aqarukum.com/api/users/allAdvertisementu', {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${localStorage.getItem('token')}`,
//         },
//       });

//       if (response.ok) {
//         const data = await response.json();
//         setUserNumber(data.number);
//       } else {
//         console.error('Failed to fetch user number');
//       }
//     } catch (error) {
//       console.error('Error fetching user number:', error);
//     }
//   };

//   const handleDeleteAdvertisement = async (advertisementId) => {
//     try {
//       const response = await fetch(`https://aqarukum.com/api/users/deleteAdvertisement/${advertisementId}`, {
//         method: 'DELETE',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${localStorage.getItem('token')}`,
//         },
//       });

//       if (response.ok) {
//         // Update the userAdvertisements state after successful deletion
//         const updatedAdvertisements = userAdvertisements.filter(ad => ad._id !== advertisementId);
//         setUserAdvertisements(updatedAdvertisements);
//       } else {
//         console.error('Failed to delete advertisement');
//       }
//     } catch (error) {
//       console.error('Error deleting advertisement:', error);
//     }
//   };

//   return (
//     <section style={{ backgroundColor: '#eee', width: '100%' }}>
//       <MDBContainer className="py-5">
//         <MDBRow>
//           <MDBCol lg="18">
//             <MDBCard className="mb-4">
//               <MDBCardBody className="text-center">
//                 <MDBCardImage
//                   src="https://cdn4.iconfinder.com/data/icons/avatars-21/512/avatar-circle-human-female-black-6-512.png"
//                   alt="avatar"
//                   className="rounded-circle"
//                   style={{ width: '190px' }}
//                   fluid
//                 />
//                 <br />
//                 <br />
//                 <p className="text-muted mb-1">{userName}</p>
//                 <p className="text-muted mb-1">{userEmail}</p>
//                 <p className="text-muted mb-1">{userNumber}</p>
//                 <p className="text-muted mb-4"></p>
//                 <div className="d-flex justify-content-center mb-2">
//                   <MDBBtn onClick={handleShowNumber} style={{ backgroundColor: '#008d6a', borderColor: '#008d6a' }}>
//                     اظهار الرقم
//                   </MDBBtn>
//                   {/* <MDBBtn outline className="ms-2 ">
//                     مراسلة
//                   </MDBBtn> */}
//                 </div>
//               </MDBCardBody>
//             </MDBCard>

//             {/* Display user advertisements */}
//             <MDBCard className="mb-4 mb-lg-8">
//               <MDBCardBody className="p-0">
//                 <MDBListGroup flush className="rounded-3">
//                   {userAdvertisements.map((advertisement) => (
//                     <MDBListGroupItem
//                       key={advertisement._id} // Assuming you have a unique identifier for each advertisement
//                       className="d-flex justify-content-between align-items-center p-3"
//                     >
//                       <MDBCardText>{advertisement.description}</MDBCardText>
//                       <MDBIcon
//                         fas
//                         icon="trash-alt fa-lg text-danger"
//                         onClick={() => handleDeleteAdvertisement(advertisement._id)}
//                         style={{ cursor: 'pointer' }}
//                       />                    </MDBListGroupItem>
//                   ))}
//                 </MDBListGroup>
//               </MDBCardBody>
//             </MDBCard>
//           </MDBCol>
//         </MDBRow>
//       </MDBContainer>
//     </section>
//   );
// };

// export default Profile;



import React, { useState, useEffect } from 'react';
import EditFormModal from './EditFormModal'; 
import { jwtDecode } from 'jwt-decode';
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBTypography,
  MDBBtn,
  MDBListGroup,
  MDBListGroupItem,
  MDBIcon,
} from 'mdb-react-ui-kit';
// import { MDBCol, MDBContainer, MDBRow, MDBCard, MDBCardText, MDBCardBody, MDBCardImage, MDBTypography, MDBIcon, MDBListGroup, MDBListGroupItem } from 'mdb-react-ui-kit';
import '../accountbox/profile.css'; // 
import AdvertisementDetailsModal from './AdvertisementDetailsModal';
import { Link } from 'react-router-dom';
const Profile = ({user}) => {  

  const [selectedAdvertisement, setSelectedAdvertisement] = useState(null);

  const [userAdvertisements, setUserAdvertisements] = useState([]);
    const [userEmail, setUserEmail] = useState('');
    const [userName, setUserName] = useState('');
    const [number, setUserNumber] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [editedUserName, setEditedUserName] = useState('');
    const [editedUserEmail, setEditedUserEmail] = useState('');
    const [editedFacAccount, setEditedFacAccount] = useState('');
    const [editedUserNumber, setEditedUserNumber] = useState('');
    const [showEditModal, setShowEditModal] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [selectedAdvertisementDetails, setSelectedAdvertisementDetails] = useState(null);


    const handleEyeIconClick = async (advertisementId) => {
      console.log(advertisementId)
      try {
        const response = await fetch(`https://aqarukum.com/api/users/getAdvertisementById/${advertisementId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
  
        if (response.ok) {
          const data = await response.json();
          setSelectedAdvertisementDetails(data);
        } else {
          console.error('Failed to fetch advertisement details');
        }
      } catch (error) {
        console.error('Error fetching advertisement details:', error);
      }
    };
  
    // Function to close the advertisement details modal
    const handleCloseAdvertisementDetails = () => {
      setSelectedAdvertisement(null);
    };
  
    const handleOpenSuccessMessage = () => {
      setShowSuccessMessage(true);
    };
  
    // Function to close the success message
    const handleCloseSuccessMessage = () => {
      setShowSuccessMessage(false);
  
      // Perform logout and redirect to the login page
      localStorage.removeItem('token');
      // Redirect to the login page (replace '/login' with your actual login route)
      window.location.href = '/account';
    };
    const handleOpenModal = () => {
      setShowEditModal(true);
    };
  
    // Function to close the modal
    const handleCloseModal = () => {
      setShowEditModal(false);
    };
    useEffect(() => {
      fetchUserAdvertisements();
    }, []);
    console.log("toti44",user.displayName)

    const fetchUserAdvertisements = async () => {
      try {

        
        const response = await fetch('https://aqarukum.com/api/users/allAdvertisementu', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (response.ok) {
     

          const data = await response.json();
          setUserAdvertisements(data);
          const decodedToken = jwtDecode(localStorage.getItem('token'));
         
            setUserEmail(decodedToken.email);
            setUserName(decodedToken.name);
          // } else {
          //   setUserEmail(user.emails[0].value);
          //   setUserName(user.displayName );
          //   console.log("user1", user.emails[0].value)
          //   console.log(user.displayName)
          // }


        } else {
          console.error('Failed to fetch user advertisements');
        }
      } catch (error) {
        console.error('Error fetching user advertisements:', error);
      }
    };
  
    const handleShowNumber = async () => {
      try {
        const response = await fetch('https://aqarukum.com/api/users/allAdvertisementu', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
  
        if (response.ok) {
          const data = await response.json();
          setUserNumber(data.number);
          console.log(data.number)
        } else {
          console.error('Failed to fetch user number');
        }
      } catch (error) {
        console.error('Error fetching user number:', error);
      }
    };
  
    const handleDeleteAdvertisement = async (advertisementId) => {
      try {
        const response = await fetch(`https://aqarukum.com/api/users/deleteAdvertisement/${advertisementId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
  
        if (response.ok) {
          // Update the userAdvertisements state after successful deletion
          const updatedAdvertisements = userAdvertisements.filter(ad => ad._id !== advertisementId);
          setUserAdvertisements(updatedAdvertisements);
        } else {
          console.error('Failed to delete advertisement');
        }
      } catch (error) {
        console.error('Error deleting advertisement:', error);
      }
    };

  



    const handleSaveChanges = async (e) => {
      e.preventDefault();
    
      try {
        const response = await fetch('http://127.0.0.1:9000/api/users/editProfile', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify({
            name: editedUserName,
            number: editedUserNumber,
            FacAccount: editedFacAccount,
          }),
        });
    
        if (response.ok) {
          // Update the user information in the state
          setUserName(editedUserName);
          setUserNumber(editedUserNumber);
          setEditedFacAccount(editedFacAccount);
          setIsEditing(false); // Exit edit mode
          window.alert('Changes saved successfully!');

          // Redirect to login page
          // window.location.href = '/account';

          

        } else {
          console.error('Failed to save changes');
        }
      } catch (error) {
        console.error('Error saving changes:', error);
      }
    };


    const [userData, setUserData] = useState({});
    useEffect(() => {
      // Fetch user data when the component mounts
      fetchUserData();
      fetchUserAdvertisements();
    }, []);
  
    const fetchUserData = async () => {
      try {
        const response = await fetch('http://127.0.0.1:9000/api/users/getUserData', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
  
        if (response.ok) {
          const data = await response.json();
          setUserData(data);
          console.log("dfgdf",userData.number)
        } else {
          console.error('Failed to fetch user data');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
  return (
    
    <div className="" style={{ backgroundColor: '#f4f5f7' }}>
      <MDBContainer className="py-5 h-100">
      {selectedAdvertisementDetails && (
  <AdvertisementDetailsModal
    advertisementDetails={selectedAdvertisementDetails}
    handleClose={handleCloseAdvertisementDetails}
  />
)}
        <MDBRow className="justify-content-start align-items-center h-100">
          <MDBCol lg="12" className="mb-4 mb-lg-0">
            <MDBCard className="mb-3" style={{ borderRadius: '.5rem' }}>
              <MDBRow className="g-0">
                <MDBCol md="3" className="gradient-custom text-center text-white"
                  style={{ borderTopLeftRadius: '.5rem', borderBottomLeftRadius: '.5rem' }}>
<MDBCardImage
  src={ user?.photos[0]?.value ||  "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp" }
  alt="Avatar"
  className="my-5"
  style={{ width: '80px' , borderRadius:"50%" }}
  fluid
/>



<MDBTypography tag="h5">{userName || (user?.displayName || 'Default Name')}</MDBTypography>
                  {isEditing ? (
  // Render the edit form
  <form onSubmit={handleSaveChanges}>
    {/* Input fields for edited information */}
    <input
      type="text"
      value={editedUserName}
      onChange={(e) => setEditedUserName(e.target.value)}
    />
    <input
      type="email"
      value={editedUserEmail}
      onChange={(e) => setEditedUserEmail(e.target.value)}
    />
    <input
      type="text"
      value={editedUserNumber}
      onChange={(e) => setEditedUserNumber(e.target.value)}
    />
    <button type="submit">Save Changes</button>
    <button type="button" onClick={() => setIsEditing(false)}>
      Cancel
    </button>
  </form>
) : (
  // Render user information
  <>
    {/* Existing user information display */}
    {/* ... (other user information) */}
    {/* <MDBBtn onClick={() => setIsEditing(true)}>Edit Profile</MDBBtn> */}
  </>
)}
                  <MDBTypography tag="h6" >مجموع الاعلانات : {userAdvertisements.length}</MDBTypography>
                  <MDBCardText></MDBCardText>
                  {/* <MDBIcon far icon="edit mb-5"  onClick={() => setIsEditing(true)}/> */}
                  <MDBIcon far icon="edit mb-5" onClick={handleOpenModal} />

{/* Render the EditFormModal component */}
<EditFormModal
  show={showEditModal}
  handleClose={handleCloseModal}
  handleSaveChanges={handleSaveChanges}
  editedUserName={editedUserName}
  setEditedUserName={setEditedUserName}
  editedUserEmail={editedUserEmail}
  editedFacAccount={editedFacAccount}
  setEditedFacAccount={setEditedFacAccount}
  setEditedUserEmail={setEditedUserEmail}
  editedUserNumber={editedUserNumber}
  setEditedUserNumber={setEditedUserNumber}
  userEmail={userEmail} 

/>                  <div className="d-flex justify-content-start justify-content-center align-items-center ">
<a href={userData.FacAccount} target="_blank">
  <MDBIcon fab icon="facebook me-3" size="lg" color='white' />
</a>       
<a href={`https://wa.me/${userData.number}`} target="_blank">
  <MDBIcon fab icon="whatsapp me-3" size="xl" color='white' />
</a>

                      {/* <a href="#!"><MDBIcon fab icon="instagram me-3" size="lg" color='white' /></a> */}
                    </div>
                </MDBCol>
                <MDBCol md="8">
                  <MDBCardBody className="p-4">
                    <MDBTypography tag="h6">المعلومات الشخصية</MDBTypography>
                    <hr className="mt-0 mb-4" />
                    <MDBRow className="pt-1">
                      <MDBCol size="6" className="mb-3">
                        <MDBTypography tag="h6">Email</MDBTypography>
                        <MDBCardText className="text-muted">{userEmail || (user?.emails[0]?.value || 'Default Email')}</MDBCardText>
                      </MDBCol>
                      <MDBCol size="6" className="mb-3">
  <MDBTypography tag="h6">Phone</MDBTypography>
  <MDBCardText className="text-muted">{userData.number}</MDBCardText>
</MDBCol>
                    </MDBRow>

                    <MDBTypography tag="h6">الاعلانات</MDBTypography>
                    <hr className="mt-0 mb-4" />

                    {userAdvertisements.map((advertisement) => (
                      
                    <MDBListGroupItem
                      key={advertisement._id} // Assuming you have a unique identifier for each advertisement
                      className="d-flex justify-content-between align-items-center p-3"
                    >

               
                      <MDBCardText>{advertisement.description}</MDBCardText>

                      <MDBIcon
                        fas
                        icon="trash-alt fa-lg text-danger  me-1"
                        onClick={() => handleDeleteAdvertisement(advertisement._id)}
                        
                        style={{ cursor: 'pointer' }}
                      />      
                 <Link to={`/pro/${advertisement._id}`}>
  <MDBIcon icon='eye fa-lg text me-2' size='3x' style={{ color: '#008d6a' }} />
</Link>

                                    </MDBListGroupItem>
                  ))}
                
                    <MDBRow className="pt-1"  >
       
                    
                      
                    
                    </MDBRow>

                  
                  </MDBCardBody>
                  
                </MDBCol>
              </MDBRow>
              
            </MDBCard>
          </MDBCol>
          
        </MDBRow>

        
      </MDBContainer>
    </div>
  );
}

export default Profile;