import React, { useEffect, useState } from "react"
import "./App.css"
import { BrowserRouter as Router, Switch, Route, Navigate } from "react-router-dom"

import Header from "./common/header/Header"
import Pages from "./pages/Pages"
import Data from "./components/Data"
import Cart from "./common/Cart/Cart"
import Footer from "./common/footer/Footer"
import Sdata from "./components/shops/Sdata"
import styled from "styled-components";
import AccountBox from "./components/accountbox"
import Product from "./components/product/Product"
import ContactForm from "./Form/ContactForm"
import MultiStepForm from "./Form/MultiStepForm"
import Categories from "./components/MainPage/Categories"
import ApartmentsForRent from "./components/MainPage/ApartmentsForRent"
import FurnishedApartments from "./components/MainPage/FurnishedApartments"
import WarehousesForRent from "./components/MainPage/WarehousesForRent"
import VillasForRent from "./components/MainPage/VillasForRent"
import FarmsForSale from "./components/MainPage/FarmsForSale"
import VillasForSale from "./components/MainPage/VillasForSale"
import BuildingsForSale from "./components/MainPage/BuildingsForSale"
import ApartmentsForSale from "./components/MainPage/ApartmentsForSale"
import LandsForSale from "./components/MainPage/LandsForSale"
import ShopsForRent from "./components/MainPage/ShopsForRent"
import { AuthProvider, useAuth } from './common/header/AuthProvider'; // Import useAuth hook
import Profile from "./components/accountbox/Profile"
import axios from "axios"
import { Link } from "@mui/material"
import { Search } from "@mui/icons-material"

function App() {

  const AppContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
  const { productItems } = Data
  const { shopItems } = Sdata


  const [CartItem, setCartItem] = useState([])

  const addToCart = (product) => {
    const productExit = CartItem.find((item) => item.id === product.id)

    if (productExit) {
      setCartItem(CartItem.map((item) => (item.id === product.id ? { ...productExit, qty: productExit.qty + 1 } : item)))
    } else {

      setCartItem([...CartItem, { ...product, qty: 1 }])
    }
  }

  const decreaseQty = (product) => {
    const productExit = CartItem.find((item) => item.id === product.id)


    if (productExit.qty === 1) {
      setCartItem(CartItem.filter((item) => item.id !== product.id))
    } else {

      setCartItem(CartItem.map((item) => (item.id === product.id ? { ...productExit, qty: productExit.qty - 1 } : item)))
    }
  }

  const [user, setUser] = useState(null);


  useEffect(() => {

    const getUser = () => {
      fetch("http://localhost:5000/auth/login/success", {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          // "Access-Control-Allow-Credentials": true,
        },

      })

        .then((response) => {
          if (response.status === 200) return response.json();
          throw new Error("authentication has been failed!");
        })
        .then((resObject) => {
          setUser(resObject.user);
          console.log(user)
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getUser();
  }, []);
  console.log("dsfsdf", user)
  return (

    <>

      <Router>
        <Route
          render={({ location }) => {
            if (location.pathname === '/account') {
              return null; // Do not render Header for "/account" path
            }

            // Render Header for all other paths
            return <Header user={user} />;
          }}
        />

        <Switch>
          <Route path='/categories' component={Categories} />
          <Route path="/" exact>
            <Pages productItems={productItems} addToCart={addToCart} shopItems={shopItems} />
          </Route>
          <Route path="/cart" exact>
            <Cart CartItem={CartItem} addToCart={addToCart} decreaseQty={decreaseQty} />
          </Route>   <Route path="/" exact>
            <AccountBox />
          </Route>
          <Route path="/pro/:id" exact>
            <Product />
          </Route>



          <Route path="/form" exact>
            <ContactForm />
          </Route>
          <Route path="/profile" exact>
            <Profile  user={user}/>
          </Route>
          <Route path="/sub" exact>
            <MultiStepForm user={user} />
          </Route>

          <Route path="/apartment" exact>
            <ApartmentsForRent />
          </Route>
          <Route path="/FurnishedApartments" exact>
            <FurnishedApartments />
          </Route>
          <Route path="/WarehousesForRent" exact>
            <WarehousesForRent />
          </Route>
          <Route path="/VillasForRent" exact>
            <VillasForRent />
          </Route>
          <Route path="/FarmsForSale" exact>
            <FarmsForSale />
          </Route>
          <Route path="/VillasForSale" exact>
            <VillasForSale />
          </Route>
          <Route path="/BuildingsForSale" exact>
            <BuildingsForSale />
          </Route>
          <Route path="/ApartmentsForSale" exact>
            <ApartmentsForSale />
          </Route>
          <Route path="/LandsForSale" exact>
            <LandsForSale />
          </Route>

          <Route path="/ShopsForRent" exact>
            <ShopsForRent />
          </Route>

          <Route path="/account" exact>
            <AccountBox />
          </Route>
          {/* <Route
              path="/"
              element={user ? <Pages /> : <He to="/account" />}
            /> */}
        </Switch>

        <Footer />
      </Router>



    </>
  )
}

export default App
