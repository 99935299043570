import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  BoldLink,
  BoxContainer,
  FormContainer,
  Input,
  LineText,
  MutedLink,
  SubmitButton,
} from "./comman";
import { Marginer } from "../marginer";
import { AccountContext } from './AccountContext';
import axios from "axios";
import { GoogleLogin } from 'react-google-login';
  export function SignupForm(props) {
    const { switchToSignin } = useContext(AccountContext);
    const history = useHistory();  // Initialize useHistory
    const [user, setUser] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [showPassword, setShowPassword] = useState(false); 
    const [showconPassword, setShowconPassword] = useState(false); 


    // const responseGoogle = async (response) => {
    //   try {
    //     const backendResponse = await axios.post(
    //       'http://localhost:9000/api/users/auth/google',
    //       { token: response.tokenId },
    //     );
    
    //     console.log('Backend response:', backendResponse.data);
    //   } catch (error) {
    //     console.error('Error handling Google Sign-In:', error);
    //   }
    // };
    async function responseGoogle(response) {
      try {
        if (response && response.profileObj) {
          const { email, name, googleId, imageUrl } = response.profileObj;
          const registrationResponse = await axios.post('http://localhost:9000/api/users/auth/google', {
            email,
            name,
            googleId,
            imageUrl,
          });
  
          if (registrationResponse.data.status === 'ok') {
            alert("Google Sign-In successful. User registered!");
            history.push(`/account`);
          } else {
            alert("Google Sign-In failed. Please try again.");
          }
        } else {
          alert("Google Sign-In response is invalid or incomplete.");
        }
      } catch (error) {
        console.error("Error handling Google Sign-In response:", error);
        alert("An error occurred while processing Google Sign-In response.");
      }
    }
    async function registerUser(e) {
      e.preventDefault();
  
      // Validate if name, email, password, and phoneNumber are not empty
      if (!name || !email || !password || !confirmPassword) {
        setErrorMessage("Please fill in all fields");
        return;
      }
  
      // Validate password length
      if (password.length < 8) {
        setErrorMessage("Password must be at least 8 characters long");
        return;
      }
  
      // Check if password and confirm password match
      if (password !== confirmPassword) {
        setErrorMessage("Password and Confirm Password do not match");
        return;
      }
  
      try {
        const response = await fetch('https://aqarukum.com/api/users/signup', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name,
            email,
            password,
          }),
        });
  
        const data = await response.json();
  
        if (data.status === 'ok') {
          // Reset error message and proceed with successful registration
          setErrorMessage('');
          alert("Register successfully");
          
  
          // Redirect the user to the OTP verification page
          history.push(`/account`);
        } else {
          // Display the error message under the button
          setErrorMessage(data.message || 'Registration failed');
        }
      } catch (error) {
        console.error("Error during registration:", error);
        setErrorMessage("An error occurred during registration. Please try again.");
      }
    }
  
    
    return (
      <BoxContainer>
        <FormContainer onSubmit={registerUser}>
          <Input
            type="text"
            placeholder="الاسم الكامل "
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <Input
            type="email"
            placeholder="البريد الالكتروني"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
    <div style={{ display: 'flex', alignItems: 'center' }}>
            <Input
              type={showPassword ? "text" : "password"} // Toggle password visibility
              value={password}
              placeholder="الرقم السري"
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              style={{
               
                cursor: 'pointer',
                border: 'none',
                background: 'none',
        
              }}
            >
              {showPassword ? (
                <i className="far fa-eye"></i> 
              ) : (
                <i className="far fa-eye-slash"></i> 
              )}
            </button>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
  <Input
    type={showconPassword ? "text" : "password"} // Toggle confirm password visibility
    value={confirmPassword}
    placeholder="تأكيد الرقم السري"
    onChange={(e) => setConfirmPassword(e.target.value)}
  />
  <button
    type="button"
    onClick={() => setShowconPassword(!showconPassword)}
    style={{
      cursor: 'pointer',
      border: 'none',
      background: 'none',
    }}
  >
    {showconPassword ? (
      <i className="far fa-eye"></i>
    ) : (
      <i className="far fa-eye-slash"></i>
    )}
  </button>
</div>

         
{/* <GoogleLogin
  clientId="466927975612-1otsma0jdphemin95k4rm0eadp9j1ir0.apps.googleusercontent.com"
  buttonText="Sign in with Google"
  onSuccess={responseGoogle}
  onFailure={responseGoogle}
  redirectUri="http://localhost:3000/auth/google/callback"
  cookiePolicy={'single_host_origin'}
/> */}
  


          <Marginer direction="vertical" margin={10} />
          <SubmitButton type="submit" onSubmit={registerUser} style={{ backgroundColor: "#008d6a" }}>
    تسجيل الدخول
  </SubmitButton>
  {errorMessage && <div style={{ color: 'red', marginTop: '10px' }}>{errorMessage}</div>}

        </FormContainer>
        <Marginer direction="vertical" margin="5px" />
        <LineText>
          لديك حساب ؟{" "}
          <BoldLink onClick={switchToSignin} href="#">
            تسجيل دخول
          </BoldLink>
        </LineText>
      </BoxContainer>
    );
  }
