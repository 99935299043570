// import React, { useEffect, useState } from "react";
// import "./style.css";
// import { Link } from "react-router-dom";

// const Wrapper = () => {
//   const [Advertisement, setAdvertisement] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [itemsPerPage] = useState(10); // Adjust the number of items per page

//   useEffect(() => {
//     async function fetchAdvertisement() {
//       try {
//         const response = await fetch('https://aqarukum.com/api/users/allAdvertisement');
//         const data = await response.json();

//         if (response.ok) {
//           setAdvertisement(data);
//         } else {
//           setError(error);
//         }
//       } catch (error) {
//         console.log(error);
//       } finally {
//         setLoading(false);
//       }
//     }

//     fetchAdvertisement();
//   }, []);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>Error: {error}</div>;
//   }

//   const indexOfLastItem = currentPage * itemsPerPage;
//   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//   const currentItems = Advertisement.slice(indexOfFirstItem, indexOfLastItem);

//   const paginate = (pageNumber) => setCurrentPage(pageNumber);
//   return (
//     <>
//       <section className='wrapper background'>
//         <div className='containerss grid2'>
//           {currentItems.map((val, index) => {
//             return (
//               <div className='product' key={index}>
//                 <div className='img icon-circle'>
//                   <img src={val.avatar} alt="Avatar" style={{ width: '50px', height: '50px', borderRadius: "50px" }} />
//                 </div>
//                 <h3>
//                   {val.propertyType === 'land' ? 'أرض للبيع' :
//                     val.propertyType === 'BuildingsForSale' ? 'عمارة للبيع' :
//                       val.propertyType === 'ShopsForRent' ? ' محل للايجار' :
//                         val.propertyType === 'FurnishedApartments' ? '  شقة مفروشة ' :
//                           val.propertyType === 'VillasForRent' ? '  استراحة للايجار' :
//                             val.propertyType === 'FarmsForSale' ? '   مزرعة للبيع' :
//                               val.propertyType === 'WarehousesForRent' ? '  مستودع للبيع ' :
//                                 val.propertyType === 'apartment' ? ' شقة للايجار ' :
//                                   val.propertyType}
//                 </h3>
//                 <p>{val.description}</p>
//                 <div className="button-div">
//                   <Link to={`/pro/${val._id}`} state={{ val }}>
//                     <button className="btn-primary">عرض التفاصيل</button>
//                   </Link>
//                 </div>
//               </div>
//             );
//           })}
//         </div>
//         <ul className="pagination justify-content-end">
//         <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
//           <button className="page-link" onClick={() => paginate(currentPage - 1)} style={{ backgroundColor: "#008d6a" , color:"white"}}>
//             Previous
//           </button>
//         </li>
//         {Array.from({ length: Math.ceil(Advertisement.length / itemsPerPage) }, (_, index) => (
//           <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
//             <button className="page-link" onClick={() => paginate(index + 1)} style={{  color:"#008d6a" }}>
//               {index + 1}
//             </button>
//           </li> 
//         ))}
//         <li className={`page-item ${currentPage === Math.ceil(Advertisement.length / itemsPerPage) ? 'disabled' : ''}`}>
//           <button className="page-link" onClick={() => paginate(currentPage + 1)} style={{backgroundColor: "#008d6a"  , color:"white"}}>
//             Next
//           </button>
//         </li>
//       </ul>
//       </section>
//     </>
//   );
// }

// export default Wrapper;
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react"
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
const Wrapper = () => {
  const [itemsPerPage] = useState(10); // Adjust the number of items per page

  const [Advertisement, setAdvertisement] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCity, setSelectedCity] = useState("All");
  const [selectedPropertyType, setSelectedPropertyType] = useState("ch"); // Change here
  const [showDropdowns, setShowDropdowns] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
 const [currentPage, setCurrentPage] = useState(1);

  const [selectedPayment, setSelectedPayment] = useState("All");

  const [minPrice, setMinPrice] = useState("All");
  const [maxPrice, setMaxPrice] = useState("All");

  const [minSpace, setminSpace] = useState("All");
  const [maxSpace, setmaxSpace] = useState("All");
  const [showFilters, setShowFilters] = useState(false);

  const handleToggleFilters = () => {
    setShowFilters(!showFilters);
  };


  // const handlePaymentChange = (selectedPayment) => {
  //   setSelectedPayment(selectedPayment);
  // };

  // const handleCityChange = (selectedCity) => {
  //   setSelectedCity(selectedCity);
  // };


  const handleCityChange = (selectedCity) => {
    console.log("Selected City changed to:", selectedCity);
    setSelectedCity(selectedCity);
    setSelectedFilters((prevFilters) => ({ ...prevFilters, city: selectedCity }));
  };

  const handlePaymentChange = (selectedPayment) => {
    setSelectedPayment(selectedPayment);
    setSelectedFilters((prevFilters) => ({ ...prevFilters, payment: selectedPayment }));
  };
  const divStyle = {
    borderRadius: '10px',
    // You can adjust the value to change the level of rounding
  };
 


  useEffect(() => {
    async function fetchAdvertisement() {
      try {
        const response = await fetch('https://aqarukum.com/api/users/allAdvertisement');
        const data = await response.json();

        if (response.ok) {
          setAdvertisement(data);
        } else {
          setError(error);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    fetchAdvertisement();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  
  // const filteredAdvertisements = selectedCity === "All"
  // ? Advertisement.filter((ad) => ad.propertyType === 'land')
  // : Advertisement.filter((ad) => ad.propertyType === 'land' && ad.selectedCity === selectedCity); 
  const filteredAdvertisements = Advertisement.filter((ad) => {
    console.log("Selected City:", selectedCity);


    const cityFilter = selectedCity === "All" || ad.selectedCity === selectedCity;
    const paymentOptionsFilter = selectedPayment === "All" || ad.selectedPayment === selectedPayment;
    const removeCommas = (value) => value.replace(/,/g, '.');


    const propertyTypeFilter = selectedPropertyType === "ch" || ad.propertyType === selectedPropertyType;
    const minPriceFilter = minPrice === "All" || parseFloat(ad.price) >= parseFloat(removeCommas(minPrice));
    const maxPriceFilter = maxPrice === "All" || parseFloat(ad.price) <= parseFloat(removeCommas(maxPrice));
    const minSpaceFilter = minSpace === "All" || parseFloat(ad.Space) >= parseFloat(removeCommas(minSpace));
    const maxSpaceFilter = maxSpace === "All" || parseFloat(ad.Space) <= parseFloat(removeCommas(maxSpace));
    console.log("the max ", (ad.price))
    console.log("the min ", parseInt(minPrice))

    console.log("the min space ", minSpace)
    console.log("the max space ", maxSpace)


    console.log("Property Type Filter:", propertyTypeFilter);
    console.log("يبيل", minSpaceFilter)
    console.log(maxSpaceFilter)
    return cityFilter && minPriceFilter && maxPriceFilter && minSpaceFilter && maxSpaceFilter && paymentOptionsFilter && propertyTypeFilter;


  });


  const sortedAdvertisements = filteredAdvertisements.slice().sort((a, b) => {
    const indexA = Advertisement.indexOf(a);
    const indexB = Advertisement.indexOf(b);
    return indexB - indexA;
  });

  const handleShowDropdowns = () => {
    setShowDropdowns(!showDropdowns);
  };

  const handleFilterDelete = (filterKey) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      delete updatedFilters[filterKey];
      return updatedFilters;
    });
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedAdvertisements.slice(indexOfFirstItem, indexOfLastItem);
  
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
    
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
  <ul className="pagination" style={{ direction: "ltr" }}>
    <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
      <button
        className="page-link"
        onClick={() => handlePageChange(currentPage - 1)}
        style={{
          backgroundColor: "#008d6a",
          color: "white",
          ":hover": {
            backgroundColor: "red",
          },
        }}
      >
        <KeyboardArrowLeftIcon />
      </button>
    </li>
    {Array.from({ length: Math.ceil(Advertisement.length / itemsPerPage) }, (_, index) => (
      <li key={index} className={`page-item ${currentPage === index + 1 ? "active" : ""}`}>
       <button
  className="page-link"
  onClick={() => handlePageChange(index + 1)}
  style={{
    backgroundColor: currentPage === index + 1 ? "#008d6a" : "#fff",
    color: currentPage === index + 1 ? "#fff" : "#008d6a",
    ":hover": {
      backgroundColor: currentPage === index + 1 ? "red" : "#008d6a",
      color: currentPage === index + 1 ? "#fff" : "red",
    },
  }}
>
  {index + 1}
</button>

      </li>
    ))}
    <li
      className={`page-item ${currentPage === Math.ceil(Advertisement.length / itemsPerPage) ? "disabled" : ""}`}
    >
      <button
        className="page-link"
        onClick={() => handlePageChange(currentPage + 1)}
        style={{
          backgroundColor: "#008d6a",
          color: "white",
          ":hover": {
            backgroundColor: "red", 
          },
        }}
      >
        <KeyboardArrowRightIcon />
      </button>
    </li>
  </ul>
</div>




 
    < div className="advertisement-container">
      {/* <div className="selected-filters">
  {Object.keys(selectedFilters).map((filterKey) => (
    <Chip
      key={filterKey}
      label={`${filterKey}: ${selectedFilters[filterKey]}`}
      onDelete={() => handleFilterDelete(filterKey)}
    />
  ))}
</div> */}

      <div>

        <button onClick={handleToggleFilters} style={{borderRadius:10, width :80 ,
        height:50 ,color :"white",border: '1px solid 008d6a' ,backgroundColor:"#008d6a", 
        transition: 'background-color 0.3s, color 0.3s', 
    cursor: 'pointer',  }}
    onMouseEnter={(e) =>{ 
      e.target.style.backgroundColor = "#007356";
    e.target.style.color = "#ffff"
    }}
    onMouseLeave={(e) => {
      e.target.style.backgroundColor = "#ffff";
      e.target.style.color = "#000"; 
      e.target.style.border =  '1px solid #008d6a '; 
    }}    >
        بحث 
        <SearchIcon  />
        
          </button>

{showFilters && (
        <Stack spacing={2} sx={{ width: 340, padding: 4, margin: 3, border: '1px solid #e1e1e1', borderRadius: '5% ',boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }} >


          <Autocomplete
            id="property-type-outlined"
            options={propertyType} // Property type array
            getOptionLabel={(option) => option.title}
            onChange={(event, newValue) => setSelectedPropertyType(newValue ? newValue.value : "ch")}
            value={propertyType.find((pt) => pt.value === selectedPropertyType) || null}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                label="نوع العقار"
                placeholder="الكل"
                dir="ltr"
              />
            )}
          />

          <Autocomplete
            id="tags-outlined"
            options={city}
            getOptionLabel={(option) => option.title}
            onChange={(event, newValue) => setSelectedCity(newValue ? newValue.value : "All")}
            value={city.find((c) => c.value === selectedCity) || null}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                label="المدينة"
                placeholder="الكل"
                dir="ltr"
              />
            )}
          />

          <Autocomplete
            id="tags-outlined"
            options={paymentOptions}
            getOptionLabel={(option) => option.title}
            onChange={(event, newValue) => setSelectedPayment(newValue ? newValue.value : "All")}
            value={paymentOptions.find((a) => a.value === selectedPayment) || null}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                label="مدة الايجار  "
                placeholder=""
                dir="ltr"
              />
            )}
          />

          <div style={{ display: 'flex', gap: '220px' }}>
            <label sx={{ flex: 1, marginRight: '5px' }}>السعر </label>
            <label sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
              <span> ريال</span>
            </label>

          </div>
          <div style={{ display: 'flex', gap: '10px' }}>

            <TextField
              label="الحد الأدنى للسعر"
              type="text"  // Use type "text" to allow commas in the input
              value={minPrice}
              onChange={(e) => setMinPrice(e.target.value)}
              sx={{ flex: 1, marginRight: '5px' }}
              dir="ltr"
            />

            <TextField
              label="الحد الأقصى للسعر"
              type="text"  // Use type "text" to allow commas in the input
              value={maxPrice}
              onChange={(e) => setMaxPrice(e.target.value)}
              sx={{ flex: 1 }}
              dir="ltr"
            />
          </div>

          <div style={{ display: 'flex', gap: '220px' }}>
            <label sx={{ flex: 1, marginRight: '5px' }}>المساحة </label>
            <label sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
              <span>م²</span>
            </label>

          </div>
          <div style={{ display: 'flex', gap: '10px' }}>

            <TextField
              label="من"
              type="text"
              value={minSpace}
              onChange={(e) => setminSpace(e.target.value)}
              sx={{ flex: 1, marginRight: '5px' }}
              dir="ltr"
            />

            <TextField
              label="الى"
              type="text"
              value={maxSpace}
              onChange={(e) => setmaxSpace(e.target.value)}
              sx={{ flex: 1 }}
              dir="ltr"
            />
          </div>







        </Stack>
         )}
      </div>



      <div >


      </div>

      <div className="contener">



        {currentItems.map((ad, index) => (


          <div key={index} className="blog-card">
            <div className="meta">
              <Link to={`/pro/${ad._id}`} state={{ ad }}>
                <div
                  className="photo"
                  style={{
                    backgroundImage: `url(${ad.avatar})`,

                  }}
                ></div>
              </Link>

            </div>

            <div className="description" >
              <h2>{ad.Title}</h2>
              <p>
                {ad.price} ريال/سعودي  </p>
              <p className="read-more" >
                <Link to={`/pro/${ad._id}`} state={{ ad }}>المزيد</Link>
              </p>
            </div>
          </div>
        ))}
      </div>

    </div>
    </>
  )
}


export default Wrapper



const city = [
  { title: 'الكل', year: 1994, value: "All" },
  { title: 'الرياض ', year: 1994, value: "City1" },
  { title: 'مكة ', year: 1972, value: "City2" },
  { title: ' المدينة المنورة', year: 1974, value: "City3" },
  { title: 'جدة', year: 1974, value: "City4" },
  { title: 'تبوك', year: 1974, value: "City5" },
  { title: 'نجران', year: 1974, value: "City6" },
  { title: 'الطائف', year: 1974, value: "City7" },
  { title: 'ينبع', year: 1974, value: "City8" },
  { title: 'الخبر', year: 1974, value: "City9" },
  { title: 'الدمام', year: 1974, value: "City10" },
  { title: 'حائل', year: 1974, value: "City11" },
  { title: 'الباحة', year: 1974, value: "City12" },
  { title: 'ضباء', year: 1974, value: "City13" },
  { title: 'الأحساء', year: 1974, value: "City14" },
  { title: 'جازان', year: 1974, value: "City16" },

];

const paymentOptions = [
  { title: 'الكل', value: 'All' },
  { title: 'يومي', value: 'daily' },
  { title: 'شهري', value: 'monthly ' },
  { title: 'سنوي', value: 'annual' },
];
const propertyType = [
  { title: 'الكل', value: 'ch' },
  { title: 'ارض للبيع', value: 'land' },
  { title: 'شقة للايجار', value: 'apartment ' },
  { title: 'شقة للبيع', value: 'ApartmentsForSale ' },
  { title: 'عمائر للبيع', value: 'BuildingsForSale' },
  { title: 'محلات للايجار ', value: 'ShopsForRent' },
  { title: "استراحة للبيع", value: 'VillasForSale' },
  { title: "مزرعة للبيع", value: 'FarmsForSale' },
  { title: "استراحة للايجار", value: 'VillasForRent' },
  { title: "مستودع للايجار", value: 'WarehousesForRent' },
  { title: " شقة مفروشة", value: 'FurnishedApartments' },
];
